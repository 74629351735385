import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { ApiErrorAlert } from 'components/Alert/ApiErrorMessage';
import { Button } from 'components/Button/Button';
import LoadingSpinner from 'components/Icons/LoadingSpinner';
import DropZone from 'components/Inputs/DropZone';
import { useService } from 'hooks/useService';
import { useToast } from 'hooks/useToast';
import { ApiError } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { ContentType } from 'services/abstract';
import { validateGeoJsonFile } from 'utils/file';

type LayerUploaderProps = {
  onUpload?: (file: UploadedFile) => void;
  onCancel?: () => void;
  onChange: (fileId: number) => void;
};

const LayerUploader = React.forwardRef<HTMLDivElement, LayerUploaderProps>(
  ({ onUpload, onCancel, onChange }, ref) => {
    const { addToast } = useToast();
    const [file, setFile] = useState<UploadedFile | undefined>(undefined);
    const layerService = useService(ContentType.LAYER);

    const { mutateAsync, isError, isLoading, error } = useMutation({
      mutationFn: async (data: { file: File }) => {
        return await layerService.uploadGeoJson(data.file);
      },
      onSuccess: (data: UploadedFile) => {
        setFile(data);
        onUpload && onUpload(data);
        onChange(data.id);
        addToast({
          title: `Fichier téléchargé`,
          description: `Fichier ${data.name} téléchargé avec succès`,
          type: 'success',
        });
      },
    });

    const handleUpload = async (file: File) => {
      try {
        await validateGeoJsonFile(file);
        mutateAsync({ file });
      } catch (e) {
        addToast({
          title: `Erreur lors de la validation du fichier GeoJSON`,
          description: (e as Error).message,
          type: 'error',
        });
      }
    };

    const handleCancel = () => {
      setFile(undefined);
      onCancel && onCancel();
    };

    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (isError) {
      <ApiErrorAlert error={error as ApiError} />;
    }

    return (
      <div className="self-center" ref={ref}>
        {file ? (
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <img
              src={layerService.getLayerThumbnailUrlById(file.id)}
              width={128}
              height={128}
            />
            <span className="mb-2 text-sm text-gray-500">{file.name}</span>
            <Button color="red" onClick={handleCancel} size="xs">
              Annuler
            </Button>
          </div>
        ) : (
          <DropZone onUpload={handleUpload} />
        )}
      </div>
    );
  }
);

LayerUploader.displayName = 'LayerUploader';

export default LayerUploader;
