import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ApiErrorAlert } from 'components/Alert/ApiErrorMessage';
import { Button } from 'components/Button/Button';
import { Label } from 'components/Inputs/Label';
import { TextInput } from 'components/Inputs/TextInput';
import { useService } from 'hooks/useService';
import { useToast } from 'hooks/useToast';
import { ApiError } from 'interfaces/api';
import { LayerStub } from 'interfaces/layer';
import { useMutation, useQueryClient } from 'react-query';
import { ContentType } from 'services/abstract';
import LayerUploader from './LayerUploader';
import { ColorPicker } from 'components/Inputs/ColorPicker';

type PageCartoLayerFormProps = {
  pageCartoId: number;
  onSubmit: (dataForm: LayerStub) => void;
};

export const PageCartoLayerForm: FC<PageCartoLayerFormProps> = ({
  pageCartoId,
  onSubmit,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const layerService = useService(ContentType.LAYER);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<LayerStub>({
    defaultValues: {
      name: '',
      fill: '#FFFFFF00',
      stroke: '#000000',
    },
  });

  const { isError, error, isLoading, mutateAsync } = useMutation({
    mutationFn: async (layer: LayerStub) => {
      return await layerService.addLayerToPageCarto(pageCartoId, layer);
    },
    onSuccess: (_response, formData) => {
      addToast({
        title: 'Contour crée',
        description: `Votre contour a été crée avec succès`,
        type: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['page-carto', pageCartoId] });
      onSubmit(formData);
    },
  });

  const onSubmitForm = (data: LayerStub) => {
    mutateAsync(data);
  };

  return (
    <div
      className="p-4 bg-slate-100 border border-slate-300 rounded shadow-xl"
      data-testid="layer-form"
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        {isError && <ApiErrorAlert error={error as ApiError} />}
        <div className="flex flex-row">
          <div className="flex flex-col w-1/2 p-2">
            <div className="px-5">
              <Label>Fichier GeoJSON</Label>
              <Controller
                name="geoJSON"
                control={control}
                defaultValue={undefined}
                rules={{
                  required: 'Le fichier GeoJSON est obligatoire',
                }}
                render={({ field }) => {
                  return <LayerUploader {...field} />;
                }}
              />
              {errors.geoJSON && (
                <div className="mt-2 text-xs text-red-600">
                  {errors.geoJSON?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-1/2 p-2">
            <div className="px-5">
              <div>
                <Label htmlFor="Name">Nom</Label>
                <TextInput
                  id="name"
                  className="w-full"
                  {...register('name', {
                    required: 'Veuillez saisir le périmètre du carte',
                  })}
                />
                {errors.name && (
                  <div className="mt-2 text-xs text-red-600">
                    {errors.name?.message}
                  </div>
                )}
              </div>
              <div>
                <Label htmlFor="fill">Couleur de fond</Label>
                <Controller
                  name="fill"
                  control={control}
                  defaultValue={'#FFFFFF00'}
                  rules={{
                    required: 'Veuillez saisir la couleur de fond',
                  }}
                  render={({ field }) => {
                    return (
                      <ColorPicker defaultColor={field.value} {...field} />
                    );
                  }}
                />
                {errors.fill && (
                  <div className="mt-2 text-xs text-red-600">
                    {errors.fill?.message}
                  </div>
                )}
              </div>
              <div>
                <Label htmlFor="stroke">Couleur de la bordure</Label>
                <Controller
                  name="stroke"
                  control={control}
                  defaultValue={'#000000'}
                  rules={{
                    required: 'Veuillez saisir la couleur du contour',
                  }}
                  render={({ field }) => {
                    return (
                      <ColorPicker defaultColor={field.value} {...field} />
                    );
                  }}
                />
                {errors.stroke && (
                  <div className="mt-2 text-xs text-red-600">
                    {errors.stroke?.message}
                  </div>
                )}
              </div>
              <div>
                <TextInput
                  id="geoJSON"
                  type={'hidden'}
                  {...register('geoJSON', {
                    required: 'Le fichier GeoJSON est obligatoire',
                  })}
                />
              </div>
              <div className="flex justify-end mt-3">
                <Button
                  data-testid="submit-button"
                  type="submit"
                  size="lg"
                  isLoading={isLoading}
                  disabled={!isValid}
                >
                  Valider
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
