import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { PaintBrushIcon } from '@heroicons/react/24/solid';
import { PencilIcon } from '@heroicons/react/24/solid';

import { FC } from 'react';
import DataGrid from 'react-data-grid';

import { Alert } from 'components/Alert/Alert';
import { Button } from 'components/Button/Button';
import { useModal } from 'hooks/useModal';
import { usePageCarto } from 'hooks/usePageCarto';
import { PageCartoLayerForm } from './PageCartoLayerForm';
import config from 'config';
import { ContentType } from 'services/abstract';
import { useService } from 'hooks/useService';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'hooks/useToast';

export const PageCartoLayerPanel: FC = () => {
  const { showModal, hideModal } = useModal();
  const queryClient = useQueryClient();
  const { pageCartoId, layers: dataGridRows } = usePageCarto();
  const layerService = useService(ContentType.LAYER);
  const { addToast } = useToast();

  const { mutateAsync: deleteLayer } = useMutation({
    mutationFn: async (layerId: number) => {
      return await layerService.deleteLayer(layerId);
    },
    onSuccess: (_response) => {
      addToast({
        title: 'Contour supprimé',
        description: `Votre contour a été supprimé avec succès`,
        type: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['page-carto', pageCartoId] });
    },
  });

  return (
    <div>
      <div className="mt-5 text-right">
        <Button
          icon={PlusIcon}
          data-testid="import-dataset"
          onClick={() =>
            showModal({
              title: 'Nouvelle couche',
              Component: PageCartoLayerForm,
              props: { pageCartoId, onSubmit: hideModal },
            })
          }
        >
          Nouvelle couche
        </Button>
      </div>
      <div className="my-2">
        {dataGridRows.length === 0 && (
          <Alert type="info">{`Aucune couche n'a été importée`}</Alert>
        )}
        {dataGridRows.length > 0 && (
          <DataGrid
            enableVirtualization={config.ENVIRONMENT !== 'test'}
            className="border"
            rowHeight={48}
            columns={[
              {
                key: 'name',
                name: 'Nom',
              },
              {
                key: 'fill',
                name: 'Fond',
                formatter({ row }) {
                  return (
                    <>
                      <PaintBrushIcon
                        width={16}
                        height={16}
                        color={row.fill}
                        style={{ display: 'inline' }}
                      />{' '}
                      {row.fill}
                    </>
                  );
                },
              },
              {
                key: 'stroke',
                name: 'Contour',
                formatter({ row }) {
                  return (
                    <>
                      <PencilIcon
                        width={16}
                        height={16}
                        color={row.stroke}
                        style={{ display: 'inline' }}
                      />{' '}
                      {row.stroke}
                    </>
                  );
                },
              },
              {
                key: 'actions',
                name: 'Actions',
                formatter({ row }) {
                  return (
                    <Button
                      icon={TrashIcon}
                      size="sm"
                      color="red"
                      onClick={() => deleteLayer(row.id)}
                    />
                  );
                },
              },
            ]}
            rows={dataGridRows}
          />
        )}
      </div>
    </div>
  );
};
