import config from 'config';
import { ApiData, ApiDocument } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { GeoJSON } from 'interfaces/geojson';
import { LayerStub } from 'interfaces/layer';
import { ContentType } from 'services/abstract';
import { LayerService } from 'services/abstract/layer';
import { validateGeoJsonFile } from 'utils/file';
import { Strapi } from './strapi';

export class RemoteLayerService extends LayerService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async getLayersByPageCartoId(
    pageCarto: number
  ): Promise<ApiData<LayerStub>[]> {
    const { data: layers } = await this.strapiService.get<LayerStub>(
      ContentType.LAYER,
      {
        filters: {
          page_carto: { $eq: pageCarto },
        },
      }
    );
    return layers;
  }

  async addLayerToPageCarto(
    pageCartoId: number,
    layer: LayerStub
  ): Promise<ApiDocument<LayerStub>> {
    return await this.strapiService.create<LayerStub>(ContentType.LAYER, {
      ...layer,
      page_carto: pageCartoId,
    });
  }

  async uploadGeoJson(file: File): Promise<UploadedFile> {
    const fileToUpload = await this.createGeoJson(file);
    return await this.strapiService.uploadFile(
      fileToUpload,
      'api::layer.layer'
    );
  }

  async getGeoJson(geoJSON: ApiData<UploadedFile>) {
    return await this.strapiService.request.get<void, GeoJSON>(geoJSON.url);
  }

  getLayerThumbnailUrlById(id: number): string {
    return `${config.API_URL}/api/layer/thumbnail/${id}`;
  }

  private async createGeoJson(file: File): Promise<File> {
    if (file.type === 'application/json') {
      return file;
    }
    const GeoJsonFileContent = await validateGeoJsonFile(file);
    return new File([JSON.stringify(GeoJsonFileContent)], `${file.name}.json`, {
      type: 'application/json',
    });
  }

  async deleteLayer(id: number): Promise<void> {
    await this.strapiService.delete(ContentType.LAYER, id);
  }
}
