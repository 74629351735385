import { ApiCollection, ApiData, ApiDocument } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { PageCarto, PageCartoStub } from 'interfaces/page-carto';
import { User } from 'interfaces/user';
import { ContentType } from 'services/abstract';
import { getImageUrl, MAP_THUMBNAIL_PLACEHOLDER } from 'utils/file';
import { PageCartoService } from '../abstract/page-carto';
import { QueryParams, Strapi } from './strapi';

export class RemotePageCartoService extends PageCartoService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async createPageCarto(
    data: PageCartoStub
  ): Promise<ApiDocument<PageCartoStub>> {
    return await this.strapiService.create<PageCartoStub>(
      ContentType.PAGE_CARTOS,
      data
    );
  }

  async getLatestPageCartos(
    page: number,
    pageSize: number
  ): Promise<ApiCollection<PageCarto>> {
    return await this.strapiService.get<PageCarto>(ContentType.PAGE_CARTOS, {
      populate: '*',
      sort: 'createdAt:desc',
      pagination: {
        page,
        pageSize,
      },
    });
  }

  async getPageCartoById(id: number): Promise<ApiDocument<PageCarto>> {
    return await this.strapiService.getById<PageCarto>(
      ContentType.PAGE_CARTOS,
      id,
      {
        populate: {
          map: {
            populate: {
              geoJSON: true,
              properties: true,
            },
          },
          layers: {
            populate: {
              geoJSON: true,
            },
          },
          owner: true,
          data_fragments: {
            populate: {
              dataset: true,
              columns: true,
            },
          },
          indicators: {
            populate: {
              variables: true,
            },
          },
          charts: true,
          tags: true,
        },
      }
    );
  }

  async uploadCsv(file: File): Promise<UploadedFile> {
    return await this.strapiService.uploadFile(
      file,
      'api::page-carto.page-carto'
    );
  }

  async getPageCartoByTagsAndSearch(
    page: number,
    searchKeywords: string,
    selectedTags: number[],
    user: User | undefined
  ): Promise<ApiCollection<PageCarto>> {
    const searchKeywordsCondition = { $contains: searchKeywords };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filters: any = {
      owner: {
        id: {
          $eq: user?.id || undefined,
        },
      },
    };
    if (searchKeywords) {
      filters['$or'] = [
        {
          map: {
            $or: [
              {
                name: searchKeywordsCondition,
              },
              {
                yearValidity: searchKeywordsCondition,
              },
              {
                mesh: searchKeywordsCondition,
              },
            ],
          },
        },
        {
          name: searchKeywordsCondition,
        },
        {
          html: searchKeywordsCondition,
        },
      ];
    }
    if (selectedTags.length > 0) {
      filters.tags = {
        id: {
          $in: selectedTags,
        },
      };
    }
    return await this.strapiService.get<PageCarto>(ContentType.PAGE_CARTOS, {
      filters: filters as QueryParams['filters'],
      populate: '*',
      sort: 'createdAt:desc',
      pagination: {
        page,
        pageSize: 9,
      },
    });
  }

  async updatePageCarto(
    id: number,
    data: Partial<PageCartoStub>
  ): Promise<ApiDocument<PageCarto>> {
    return await this.strapiService.update(ContentType.PAGE_CARTOS, id, {
      data,
    });
  }

  async uploadCover(file: File, refId: number): Promise<UploadedFile> {
    return await this.strapiService.uploadFile(
      file,
      'api::page-carto.page-carto',
      `${refId}_thumbnail.png`,
      refId,
      'cover'
    );
  }

  async deletePageCarto(id: number): Promise<void> {
    await this.strapiService.delete(ContentType.PAGE_CARTOS, id);
  }

  getImageUrl = (
    cover: ApiData<UploadedFile> | undefined,
    format: 'thumbnail' | 'small' | 'medium' | 'large' = 'thumbnail'
  ) => {
    return getImageUrl(cover, format, MAP_THUMBNAIL_PLACEHOLDER);
  };
}
