import { ChartService } from './chart';
import { DatasetService } from './dataset';
import { EquationService } from './equation';
import { GeoMapService } from './geo-map';
import { IndicatorService } from './indicator';
import { LayerService } from './layer';
import { PageCartoService } from './page-carto';
import { SnapshotService } from './snapshot';
import { TagService } from './tag';
import { UserService } from './user';

export enum ContentType {
  PAGE_CARTOS = 'page-cartos',
  GEO_MAPS = 'geo-maps',
  DATASET = 'datasets',
  LAYER = 'layers',
  DATA_FRAGMENT = 'data-fragments',
  // COLUMN = 'columns',
  INDICATOR = 'indicators',
  EQUATION = 'equations',
  USERS = 'users',
  TAGS = 'tags',
  CHARTS = 'charts',
  SNAPSHOTS = 'snapshots',
  FILES = 'upload',
}

export type DatastoreService = {
  [ContentType.CHARTS]: ChartService;
  [ContentType.DATASET]: DatasetService;
  [ContentType.DATA_FRAGMENT]: DatasetService;
  [ContentType.EQUATION]: EquationService;
  [ContentType.GEO_MAPS]: GeoMapService;
  [ContentType.LAYER]: LayerService;
  [ContentType.INDICATOR]: IndicatorService;
  [ContentType.PAGE_CARTOS]: PageCartoService;
  [ContentType.SNAPSHOTS]: SnapshotService;
  [ContentType.TAGS]: TagService;
  [ContentType.USERS]: UserService;
};
