import { ApiData, ApiDocument } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { GeoJSON } from 'interfaces/geojson';
import { LayerStub } from 'interfaces/layer';

export abstract class LayerService {
  abstract getLayersByPageCartoId(
    pageCarto: number
  ): Promise<ApiData<LayerStub>[]>;
  abstract addLayerToPageCarto(
    pageCartoId: number,
    indicator: LayerStub
  ): Promise<ApiDocument<LayerStub>>;
  abstract uploadGeoJson(file: File): Promise<UploadedFile>;
  abstract getGeoJson(geoJSON: ApiData<UploadedFile>): Promise<GeoJSON>;
  abstract getLayerThumbnailUrlById(id: number): string;
  abstract deleteLayer(id: number): void;
}
